<template>
    <div>
        <!-- h2h design is same with match content -->
        <!-- && !isSkeletonLoading.h2h -->
        <div class="match-detail__content-title has-space h2h-title__wrapper"  v-show="!isShowNoData"  @click="handleShowH2HAlertClick()"  @mouseleave="handleShowH2HAlert('leave')">
            <span class="h2h-title__text" >{{$t('H2H')}}</span> 
            <div class="h2h-alert__wrapper" >
                <img class="img-icon pointer" src="../../../../../static/images/icons/icon_h2h_alert.png" >
                <div class="tooltips-wrapper" v-show="isShowH2HAlert">
                    <div class="tooltips-triangle"></div>
                    <div class="tooltips-content">
                        <p>{{$t('H2H_ALERT_MSG')}}</p>
                    </div>
                </div>
                </div>
        </div>
        <div v-if="!isMobile"  >     
            <div class="match-wrapper" v-show="!isLoading && !isShowNoData && !isSkeletonLoading.h2h" v-for="(o, index) in matchList" :key="index" >
                <div class="display-flex-align-center home-match__title-wrapper" >
                    <div class="home-match__title h2h-match__title">{{$t(o.leagueName)}}</div>
                </div>

                <div class="match-container" v-for="(level2, index) in o.matchList" :key="index">
                    <div class="display-flex-sb" style="width:50%">
                        <div class="font-s">
                            <span :class="{'match-status__live': level2.date==='Live'}"> {{level2.date}}</span>							
                        </div>
                        <Navigation :id="level2.id1" :category="'team'" :name="level2.team1"  class="match-detail">
                            <span class="mr-05rem match-detail__name">{{level2.team1}}</span>
                            <Icon :logoUrl=level2.logo1  class="mr-1rem"></Icon>
                            <div class="match-detail__score">{{level2.team1_score}}</div>      
                            <div class="match-middle__seperator">:</div>
                        </Navigation>                 
                    </div> 

                    <div class="display-flex-sb flex-1 h-100" >
                        <Navigation :id="level2.id2" :category="'team'" :name="level2.team2"  class="match-detail">
                            <div class="mr-1rem match-detail__score">{{level2.team2_score}}</div>
                            <Icon :logoUrl=level2.logo2 class="mr-05rem"></Icon>
                            <span>{{level2.team2}}</span>
                        </Navigation>  
                        <div class="home-match__fav mr-05rem" :class="{selected: level2.fav}" v-show="isShowFav" @click="selectFavMatch(level2)"></div>
                    </div>                           
                </div>
                
            </div>
        
        </div>
        <div v-if="isMobile">     
            <div class="home-match__wrapper has-space" v-show="!isLoading && !isShowNoData && !isSkeletonLoading.h2h"  v-for="(o, index) in matchList" :key="index" >
                <div class="display-flex home-match__title-wrapper">
                    <div class="icon-league mr-05rem">
                        <img :src="`${s3ImgUrl}/${o.leagueLogo}`" class="img-contain">
                    </div>
                    <div class="home-match__title  h2h-match__title">{{o.leagueName}}</div>
                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_14720_44477)">
                            <path d="M10.3345 11.9602L13.5786 8.70898L10.3345 5.45773L11.3332 4.45898L15.5832 8.70898L11.3332 12.959L10.3345 11.9602Z" fill="white"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_14720_44477">
                                <rect width="17" height="17" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg> 
                </div>
                
                <div class="home-match__container" >                
                    <div class="home-match__card h2h-match__card" v-for="(level2, index) in o.matchList" :key="index">   

                        <p class="home-match__time h2h-match__time">
                            <span :class="{'match-status__live': level2.date ==='Live'}">{{level2.date}}</span> 
                        </p>
                        <div class="display-flex-fe">
                            <div class="home-match__detail-wrapper">        
                                <Navigation :id="level2.id1" :category="'team'" :name="level2.team1"  class="home-match__detail">
                                    <div class="home-match__detail-opponent">
                                        <Icon :logoUrl=level2.logo1  class="mr-1rem"></Icon>
                                        <span>{{level2.team1}}</span>								
                                    </div>                               
                                    <div class="display-flex">
                                        <div class="home-match__detail-score">{{level2.team1_score}}</div>
                                    </div>                      
                                </Navigation>
                                <Navigation :id="level2.id2" :category="'team'" :name="level2.team2"  class="home-match__detail">
                                        <div class="home-match__detail-opponent">
                                            <Icon  :logoUrl=level2.logo2  class="mr-1rem"></Icon>
                                            <span>{{level2.team2}}</span>                                    
                                        </div>                               
                                        <div class="display-flex">
                                            <div class="home-match__detail-score">{{level2.team2_score}}</div>
                                        </div>                      
                                </Navigation>
                            </div>
                            <div  v-show="isShowWinLose">
                                <div class="home-match__win-lose" :class="level2.winLose">{{level2.winLose}}</div>
                            </div>
                            
                            <div class="home-match__fav" :class="{selected: level2.fav}" v-show="isShowFav" @click="selectFavMatch(level2)"></div>
                        </div>       
                    </div>
                </div> 
            </div>   
        </div>
        <Loading v-show="isLoading && !isMobile"></Loading>
        <div class="no-data" v-show="isShowNoData && !isLoading">{{$t('NO_H2H')}}</div>
        <!-- <GeneralContentSkeleton v-show="isSkeletonLoading.h2h && isMobile"></GeneralContentSkeleton> -->
    </div>
</template>

<script>
import config from "@/js/config.js";
import Loading from '@/components/indicator/Loading.vue'
import GeneralContentSkeleton from '@/components/skeletonLoading/GeneralContentSkeleton.vue';
import {mapGetters,mapMutations,mapActions} from 'vuex'

export default {
  components:{
    Loading,
    GeneralContentSkeleton,
  },
  props: {
    matchObj: {
      	type: Array
    },
    isShowFav : {
        type:Boolean
    },
    isShowWinLose : {
        type:Boolean
    },
    WinLoseResult: {
      	type: String
    },
    params:{
      type:Object
    },
  },


    data() {
        return { 
            s3ImgUrl: config.s3ImgUrl,
            path :[],
            matchList:[],
            isLoading:false,  
            isShowNoData :true,
            matchId:'',

            isShowH2HAlert:false,
            isSkeletonLoading: {
                h2h: false,
            },
        }
    },

	computed:{
      ...mapGetters([
            'isMobile',
            'currentLocale',
            'currentSportType',
            'manualRefreshMatchDetail',
      ]),
      // matchList(){ //filter for matchList if score ==="", then show "?"			
      //   let matchList = this.matchList;
      //   for (let level1 = 0; level1<matchList.length; level1++) {
      //     for (let level2 = 0; level2<matchList[level1].matchList.length; level2++){
      //       for (let level3 = 0; level3<matchList[level1].matchList[level2].opponents.length; level3++){
      //         if (matchList[level1].matchList[level2].opponents[level3].score ===""){
      //           matchList[level1].matchList[level2].opponents[level3].score = "-"
      //         }
      //       }
      //     }
      //   }
      //   return matchList
      // }
	},
    watch:{
         currentLocale: {
             deep:true,
            handler(newVal,oldVal) {
             this.init();
               // this.getLeagueListForRankingTable(newVal.leagueIdList);
            }
        },
        manualRefreshMatchDetail: {
            deep: true,
            handler(newVal, oldVal) {
                if (newVal) {
                    this.getH2hInfoData();
                }
            }
        }
     },
    created() {
        this.matchId = this.$route.params.matchId;   
    },
    mounted() {
       
       //this.getCurrentSportTypeByUrl();
	    this.getH2hInfoData();
    },

    methods:{
        ...mapMutations([
            "MANUAL_REFRESH_MATCH_DETAIL"
        ]),
      	...mapActions([
            "getMatchDetailScore",
            "getBasketballH2hInfo",
			]),
        init(){
            this.getH2hInfoData();
        },
        async getH2hInfoData(newVal){
    
            this.isLoading = true;
              
            let params = {
                 matchId:this.matchId,
            }
            if (this.manualRefreshMatchDetail) {
                this.isSkeletonLoading.h2h = true;
            }
            const result = await this.getBasketballH2hInfo(params);
            if (this.manualRefreshMatchDetail) {
                this.isSkeletonLoading.h2h = false;
                this.verifyIfAllAPICallsDone();
            }
       
            this.matchList = result.result.h2h.leagueMatchList;

            this.isLoading = false;

            if (this.matchList) {   
                if (this.matchList.length===0) {
                    this.isShowNoData = true;
                } else {
                    this.isShowNoData = false;
                }                               
            } else {
                 this.isShowNoData = true;
            }
      
    	},

        handleShowH2HAlert(action){
            if (action === 'enter'){
                this.isShowH2HAlert = true;
            } else {
                this.isShowH2HAlert = false; 
            }
        },
        handleShowH2HAlertClick(){
            this.isShowH2HAlert = !this.isShowH2HAlert;
        },

        selectFavMatch(o){
            o.fav =!o.fav
        },

        verifyIfAllAPICallsDone() {
            if (!this.isSkeletonLoading.h2h) {
                this.MANUAL_REFRESH_MATCH_DETAIL();
            }
        },

     }
}
</script>

<style scoped>
.home-match__win-lose{
    background-color: green;
    border-radius: 50%;
    padding: .3rem;
    color: white;
    width: .75rem;
    border: .1rem solid white;
    height: .75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: .5rem;
}
.home-match__win-lose.W{
    background-color: #5CD66C;    
}
.home-match__win-lose.L{
    background-color: #CF283E;    
}
    .home-match__win-lose.D{
    background-color:   #ED9C5A;    
}
.match-wrapper{
    margin-bottom: 1.2rem;
}
.match-container{
    display: flex;
    align-items: center;
    background-color: #212B30;
    color: #CFD9DE;
    margin-bottom: .5rem;
    padding: .6rem .63rem;
    border-radius: .5rem;
    font-size: .88rem;
    text-align: left;
    width: auto !important;
}
.match-container:hover{
    background-color: var(--bg-hover);
}
.match-status__live{
    background-color: #CF283E;
    padding: .2rem;
    border-radius: .3rem;
    font-size:.65rem
}
.match-detail{
    display:flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: var(--color-grey-light);
}
.match-detail__score{
    width: 2rem;
    text-align: center;
}
.match-detail__name{
    width: 8rem;
    overflow: hidden;
    text-align: right;
}
.match-middle{
    flex: 0.5;
    display: flex;
    justify-content: center;
    align-items: center;
    color:white;
}
.match-middle__seperator{
    margin: 0 .5rem;
    font-weight: bold;
    font-weight: bold;
}
    
.h2h-title__wrapper{
    display: flex;
    align-items: center;
    padding-bottom: 1.6rem;
}
.h2h-match__title:hover{
    color:white !important;
}
.h2h-title__text{
    margin-right: .4rem;
}
.h2h-alert__wrapper{
    width: 1.2rem;
    height: 1.2rem;
    position: relative;
}
.h2h-match__card{
    display: block;
    padding: .5rem 0.8rem .5rem .5rem;
}
.h2h-match__card:hover{
    background-color: var(--color-grey-40);
    border-color: var(--color-grey-40);
}
.h2h-match__time{
    width: auto;
}
.tooltips-wrapper{
    position: absolute;
    width: 16rem;
    left: 1.5rem;
    top: -.5rem;
    display: flex;
}
.tooltips-triangle{
    position: relative;
    top: .5rem;
    width: 2.7rem;
    height: 1rem;
    background: url(../../../../../static/images/tooltips_triangle.png) center/cover no-repeat;
}
.tooltips-content{
    background-color: white;
    padding: .5rem;
    color: black;
    line-height: 1rem;
    font-size: .7rem;
    border-radius: .5rem;
}
@media (max-width: 768px) {
    .home-match__title-wrapper {
        display: flex;
        align-items: center;
    }
    .icon-league img {
        border-radius: 0.15rem;
    }
}
</style>